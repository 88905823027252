import React from 'react';
import styled from 'styled-components';
import ProfilePic from '../images/Screenshot_1.png';
import { FaDownload } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';
import { Tooltip } from 'react-tooltip';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  opacity: 0;
  animation: fadeIn 0.6s ease-out forwards;
  transition: all 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    padding: 30px 16px;
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin-bottom: 24px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1),
              0 0 0 4px rgba(97, 218, 251, 0.1);
  border: 4px solid rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.6s ease-out 0.2s forwards;
  filter: brightness(1.02);

  &:hover {
    transform: scale(1.02) translateY(-5px);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15),
                0 0 0 4px rgba(97, 218, 251, 0.2);
  }

  @media (max-width: 768px) {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: 16px;
  opacity: 0;
  animation: fadeIn 0.6s ease-out 0.4s forwards;
  transition: color 0.3s ease;

  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 12px;
  }
`;

const HighlightedName = styled.span`
  background: linear-gradient(120deg, #61dafb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 600px;
  opacity: 0;
  animation: fadeIn 0.6s ease-out 0.6s forwards;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: var(--text-secondary);
  margin: 0;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 1.6;
  transition: color 0.3s ease;

  &:first-of-type {
    color: var(--text-tertiary);
    font-weight: 500;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BoardGames = styled.span`
  color: #9333ea;  // Purple
  font-weight: 500;
`;

const Pickleball = styled.span`
  color: #b2d934;  // Neon yellow
  font-weight: 500;
`;

const NewPlaces = styled.span`
  color: #22c55e;  // Green
  font-weight: 500;
`;

const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(120deg, #61dafb, #3b82f6);
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.1);
  opacity: 0;
  animation: fadeIn 0.6s ease-out 0.8s forwards;
  position: relative;
  overflow: hidden;

  svg {
    font-size: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 12px rgba(59, 130, 246, 0.2);
    background: linear-gradient(120deg, #61dafb, #2563eb);
  }

  &:active {
    transform: translateY(-1px);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    margin-top: 20px;
  }
`;

const BadgeWrapper = styled.div`
  margin-top: 24px;
  opacity: 0;
  animation: fadeIn 0.6s ease-out 1s forwards;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 768px) {
    margin-top: 20px;
    gap: 16px;
  }
`;

const BadgeImage = styled.img`
  width: 100px;
  height: auto;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  cursor: pointer;

  &:hover {
    transform: scale(1.05) translateY(-5px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15)) brightness(1.05);
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--divider-color),
    transparent
  );
  margin: 24px 0;
  transition: background 0.3s ease;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const StyledTooltip = styled(Tooltip)`
  background-color: rgba(15, 23, 42, 0.98) !important;
  backdrop-filter: blur(10px);
  border-radius: 10px !important;
  padding: 16px !important;
  font-size: 14px !important;
  line-height: 1.6 !important;
  max-width: 300px !important;
  z-index: 9999 !important;
  opacity: 1 !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2),
              0 2px 8px rgba(0, 0, 0, 0.1) !important;
  transform: none !important;
  transition: opacity 0.2s ease !important;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 240px;
`;

const TooltipTitle = styled.div`
  background: linear-gradient(120deg, #61dafb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.01em;
  margin-bottom: 2px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
`;

const TooltipDate = styled.div`
  color: #e2e8f0;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  
  &:last-child {
    border-bottom: none;
  }
  
  span {
    color: #94a3b8;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 500;
  }
`;

const Header = () => {
  const { isDarkMode } = useTheme();

  return (
    <HeaderWrapper isDarkMode={isDarkMode}>
      <ProfileImage src={ProfilePic} alt="Binh" isDarkMode={isDarkMode} />
      <Title isDarkMode={isDarkMode}>
        Hey there, my name is <HighlightedName>Binh</HighlightedName>
      </Title>
      <SubtitleWrapper>
        <Subtitle isDarkMode={isDarkMode}>
          I am a System Engineer located in Fairfax, Virginia
        </Subtitle>
        <Subtitle isDarkMode={isDarkMode}>
          When I'm not fixing problems, I'm strategizing in{' '}
          <BoardGames>board games</BoardGames>, playing{' '}
          <Pickleball>pickleball</Pickleball>, or discovering{' '}
          <NewPlaces>new places</NewPlaces>.
        </Subtitle>
      </SubtitleWrapper>
      <DownloadButton 
        href="/Binh_Pham_Resume.pdf" 
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaDownload /> Download Resume
      </DownloadButton>
      <Divider isDarkMode={isDarkMode} />
      <BadgeWrapper>
        <a 
          href="https://www.credly.com/badges/9bf2d58c-0e78-4848-9e27-a28d5b321df6" 
          target="_blank" 
          rel="noopener noreferrer"
          data-tooltip-id="aws-badge"
          data-tooltip-place="top"
        >
          <BadgeImage 
            src="https://images.credly.com/size/340x340/images/00634f82-b07f-4bbd-a6bb-53de397fc3a6/image.png" 
            alt="AWS Cloud Practitioner Badge" 
          />
        </a>
        <StyledTooltip
          id="aws-badge"
          place="top"
          delayShow={200}
          float={true}
          noResize={true}
          offset={12}
        >
          <TooltipContent>
            <TooltipTitle>AWS Certified Cloud Practitioner</TooltipTitle>
            <TooltipDate>
              <span>Issued:</span> December 2024
            </TooltipDate>
            <TooltipDate>
              <span>Expires:</span> December 2027
            </TooltipDate>
          </TooltipContent>
        </StyledTooltip>

        <a 
          href="https://www.lpi.org/our-certifications/linux-essentials-overview/"
          target="_blank" 
          rel="noopener noreferrer"
          data-tooltip-id="lpi-badge"
          data-tooltip-place="top"
        >
          <BadgeImage 
            src="https://images.credly.com/size/340x340/images/f414bc95-cdcb-4ac9-bdcc-38d3bc6fa386/LPI_Essentials.png"
            alt="LPI Linux Essentials Badge" 
          />
        </a>
        <StyledTooltip
          id="lpi-badge"
          place="top"
          delayShow={200}
          float={true}
          noResize={true}
          offset={12}
        >
          <TooltipContent>
            <TooltipTitle>LPI Linux Essentials</TooltipTitle>
            <TooltipDate>
              <span>Issued:</span> December 2024
            </TooltipDate>
            <TooltipDate>
              <span>Expires:</span> Lifetime
            </TooltipDate>
          </TooltipContent>
        </StyledTooltip>
      </BadgeWrapper>
    </HeaderWrapper>
  );
};

export default Header;
