import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaGlobe, FaTableTennis, FaMoon, FaSun } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 20px;
  gap: 24px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(100, 116, 139, 0.2),
      transparent
    );
  }

  @media (max-width: 768px) {
    padding: 24px 16px;
    gap: 20px;
  }
`;

const IconLink = styled.a`
  color: #64748b;
  font-size: 24px;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background: #3b82f6;
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover::after {
    width: 80%;
  }

  &:hover {
    transform: translateY(-2px);
    color: #3b82f6;
    opacity: 1;
    background-color: rgba(59, 130, 246, 0.1);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    font-size: 22px;
    padding: 6px;
  }
`;

const ThemeToggle = styled(IconLink)`
  background: none;
  border: none;
  cursor: pointer;
  color: #64748b;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px) rotate(12deg);
  }

  &:active {
    transform: translateY(0) rotate(0deg);
  }

  &:hover {
    transform: translateY(-2px);
    color: #3b82f6;
    opacity: 1;
    background-color: rgba(59, 130, 246, 0.1);
  }
`;

const Footer = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <FooterWrapper>
      <IconLink 
        href="https://www.linkedin.com/in/bphamt/" 
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <FaLinkedin />
      </IconLink>
      <IconLink 
        href="https://github.com/bphamt" 
        target="_blank"
        rel="noopener noreferrer"
        aria-label="GitHub"
      >
        <FaGithub />
      </IconLink>
      <IconLink 
        href="#" 
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Personal Wiki"
      >
        <FaGlobe />
      </IconLink>
      <IconLink 
        href="https://dashboard.dupr.com/dashboard/player/7825601900" 
        target="_blank"
        rel="noopener noreferrer"
        aria-label="DUPR Rating"
      >
        <FaTableTennis />
      </IconLink>
      <ThemeToggle
        as="button"
        onClick={toggleTheme}
        aria-label="Toggle dark mode"
      >
        {isDarkMode ? <FaSun /> : <FaMoon />}
      </ThemeToggle>
    </FooterWrapper>
  );
};

export default Footer;
