export const fileContents = {
  '/home/guest/certifications/AWS/AWS_Cloud_Practitioner.txt': 
`AWS Certified Cloud Practitioner
Issued: December 2024
Expiration: December 2027

This certification verifies fundamental knowledge of:
• AWS Cloud concepts
• Security and compliance
• Technology
• Billing and pricing
• Core AWS services`,

  '/home/guest/experience/System_Engineer.txt':
`Position: System Engineer
Company: Micron21
Location: Melbourne, Australia
Duration: March 2021 - Present

• Experience in Active Directory, Exchange/Office 365, and Microsoft products
• Maintain security, backup, and redundancy strategies
• Use Python & BASH to implement server shell scripting (https://github.com/bphamt)
• Performed security reviews, assessments, audits, and analysis, including vulnerability scans and audit logs
• Proactively ensure the highest levels of systems and infrastructure availability overnight`,

  '/home/guest/experience/Advanced_Product_Support.txt':
`Position: Jr. System Admin
Company: InMotion Hosting
Location: Virginia Beach, Virginia
Duration: December 2018 - March 2021

• Utilized Tier 2/3 level access to resolve complex issues across all services
• Acted as an escalation point for Tier 1 & Tier 1 Expert Support Representatives
• Experience with security compliance (PCI, HIPAA)
• Expertise in troubleshooting & installing DNS, SSL, Apache, NGINX
• Experience with databases including MySQL, MongoDB, Redis, MariaDB
• Managed backup and recovery of servers, websites, and databases
• Familiar with Virtualization (Virtuozzo/KVM)
• Experience with Veeam & Acronis
• Managed server security systems including firewalls and web application firewalls`,

  '/home/guest/experience/Tier_1_Expert.txt':
`Position: Tier 1 Expert
Company: InMotion Hosting
Location: Virginia Beach, Virginia
Duration: July 2018 - December 2018

• Acted as a mentor to Tier 1 Support Representatives
• Utilized Tier 1.5 level access to resolve complex issues and improve efficiency
• Provided escalation support for challenging technical issues
• Helped improve team efficiency and knowledge sharing`,

  '/home/guest/experience/Tier_1_Support.txt':
`Position: Tier 1 Support
Company: InMotion Hosting
Location: Virginia Beach, Virginia
Duration: May 2017 - July 2018

• Assisted with technical issues through inbound calls, chats, and tickets in a 24/7 call center
• Helped customers setup & troubleshoot their emails, websites, and servers
• Accurately recorded all customer incidents in Zendesk ticket tracking software
• Provided first-line support for hosting-related inquiries`,

  '/home/guest/experience/Resident_Advisor.txt':
`Position: Resident Advisor
Company: ODU Housing & Residence Life
Location: Norfolk, Virginia
Duration: August 2016 - January 2017

• Promoted academic excellence and addressed student needs for 40 direct residents
• Supervised a total of 437 residents in the housing complex
• Communicated and collaborated with various campus offices including counseling, student activities, and security
• Supported the mission of the University and Department of Housing and Residence Life`,

  '/home/guest/experience/IT_Consultant.txt':
`Position: IT Consultant
Company: ODU Systems Group
Location: Norfolk, Virginia
Duration: January 2016 - August 2016

• Maintained IT infrastructure within ODU computer science department
• Managed HPC Clusters, ESXi clusters, Microsoft Print Server, and SCCM
• Installed new operating systems, security fixes, and network monitoring systems
• Provided technical support to faculty and staff
• Assisted with infrastructure upgrades and maintenance`
}; 