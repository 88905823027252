import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { fileContents } from '../data/fileContents';

const TerminalWrapper = styled.div`
  background: linear-gradient(
    165deg,
    #1a1b26 0%,
    #1f2133 50%,
    #1a1b26 100%
  );
  color: #d4d4d4;
  font-family: 'JetBrains Mono', 'Fira Code', 'Courier New', monospace;
  border-radius: ${props => props.isFullscreen ? '0' : '12px'};
  padding: 20px;
  width: 100%;
  height: ${props => props.isMinimized ? '30px' : props.isFullscreen ? '100vh' : '600px'};
  margin: ${props => props.isFullscreen ? '0' : '0'};
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: default;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;

  &:focus-within {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15),
                0 0 0 1px rgba(97, 218, 251, 0.1);
  }

  @media (max-width: 768px) {
    height: ${props => props.isMinimized ? '30px' : props.isFullscreen ? '100vh' : '300px'};
    padding: 12px;
    margin: 0 10px;
    width: calc(100% - 20px);
    font-size: 13px;
  }
`;

const HeaderBar = styled.div`
  background: linear-gradient(
    90deg,
    rgba(45, 45, 45, 0.8) 0%,
    rgba(35, 35, 35, 0.8) 100%
  );
  height: 30px;
  border-radius: ${props => props.isFullscreen ? '0' : '10px 10px'} ${props => props.isMinimized ? props.isFullscreen ? '0' : '10px' : '0'} ${props => props.isMinimized ? props.isFullscreen ? '0' : '10px' : '0'};
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin: -20px -20px ${props => props.isMinimized ? props.isFullscreen ? '0' : '-20px' : '10px'} -20px;
  backdrop-filter: blur(8px);

  @media (max-width: 768px) {
    height: 22px;
    margin: -12px -12px ${props => props.isMinimized ? props.isFullscreen ? '0' : '-12px' : '8px'} -12px;
    padding: 0 8px;
  }
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => {
    switch(props.color) {
      case '#ff5f57':
        return 'linear-gradient(135deg, #ff5f57 0%, #ff4343 100%)';
      case '#ffbd2e':
        return 'linear-gradient(135deg, #ffbd2e 0%, #ffac38 100%)';
      case '#28c940':
        return 'linear-gradient(135deg, #28c940 0%, #23bf3b 100%)';
      default:
        return props.color;
    }
  }};
  margin-right: 8px;
  transition: all 0.2s ease;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    filter: brightness(1.1);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
    margin-right: 6px;
  }
`;

const TerminalContent = styled.div`
  height: calc(100% - 40px);
  overflow-y: auto;
  padding-right: 10px;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 3px;
    transition: background 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  @media (max-width: 768px) {
    height: calc(100% - 30px);
    padding-right: 5px;
  }
`;

const CommandOutput = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const CommandLine = styled.div`
  display: flex;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 14px;
  align-items: center;
  margin-bottom: 2px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const Prompt = styled.span`
  color: #61dafb;
  margin-right: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.3);

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const Input = styled.input`
  background: none;
  border: none;
  color: #d4d4d4;
  outline: none;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  caret-color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
  margin-left: -8px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 13px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const BlinkingCursor = styled.span`
  display: inline-block;
  width: 8px;
  height: 15px;
  background-color: #61dafb;
  margin-left: -8px;
  animation: blink 1s step-end infinite;
  border-radius: 1px;
  opacity: 0.8;

  @keyframes blink {
    0%, 100% { opacity: 0.8; }
    50% { opacity: 0; }
  }

  @media (max-width: 768px) {
    height: 13px;
  }
`;

const FolderList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 2px;
  margin-left: -6px;
  line-height: 1.6;
`;

const Folder = styled.span`
  color: #42a5f5;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  text-shadow: 0 0 8px rgba(66, 165, 245, 0.2);
  transition: all 0.2s ease;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0;

  &:hover {
    background-color: rgba(66, 165, 245, 0.1);
    text-decoration: ${props => props.onClick ? 'none' : 'none'};
  }
`;

const Script = styled.span`
  color: #50fa7b;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  transition: all 0.2s ease;
  padding: 2px 6px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(80, 250, 123, 0.1);
    text-decoration: ${props => props.onClick ? 'none' : 'none'};
  }
`;

const ErrorMessage = styled.div`
  margin-left: 0;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 2px;

  @media (max-width: 768px) {
    font-size: 13px;
  }

  span {
    color: #ffb86c;
    text-shadow: 0 0 8px rgba(255, 184, 108, 0.2);
  }

  .permission-denied {
    color: #ff5555;
    text-shadow: 0 0 8px rgba(255, 85, 85, 0.2);
  }
`;

const CommandText = styled.span`
  font-size: 14px;
  line-height: 1.2;
  text-shadow: 0 0 1px rgba(212, 212, 212, 0.1);

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ProjectFile = styled.span`
  color: #50fa7b;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 2px 6px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(80, 250, 123, 0.1);
    text-decoration: none;
  }
`;

const SkillItem = styled.span`
  color: #d4d4d4;
  cursor: default;
  padding: 2px 6px;
  border-radius: 4px;
`;

const WelcomeMessage = styled.div`
  white-space: pre;
  color: #d4d4d4;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const TextFile = styled.span`
  color: #d4d4d4;
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 4px;
  text-shadow: 0 0 1px rgba(212, 212, 212, 0.1);
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(212, 212, 212, 0.1);
    text-decoration: none;
    color: #ffffff;
  }
`;

const BootMessage = styled.div`
  color: ${props => props.color || '#d4d4d4'};
  margin: 2px 0;
  font-family: 'Courier New', monospace;
  font-size: 12px;
  line-height: 1.2;
`;

const BootScreen = ({ onBootComplete }) => {
  const [bootStep, setBootStep] = useState(0);
  const bootContentRef = useRef(null);
  const bootMessages = useMemo(() => [
    { text: 'Loading Binh Linux...', delay: 500, color: '#61dafb' },
    { text: '[    0.000000] Initializing cgroup subsys cpuset', delay: 100 },
    { text: '[    0.000000] Initializing cgroup subsys cpu', delay: 100 },
    { text: '[    0.000000] Linux version 5.10.102-99.473.bnh2.x86_64', delay: 200 },
    { text: '[    0.000000] Command line: BOOT_IMAGE=/boot/vmlinuz-5.10.102-99.473.bnh2.x86_64 root=UUID=ea3f6f54-c8ea', delay: 100 },
    { text: '[    0.000000] x86/fpu: Supporting XSAVE feature 0x001: \'x87 floating point registers\'', delay: 100 },
    { text: '[    0.000000] e820: BIOS-provided physical RAM map:', delay: 100 },
    { text: '[    0.000000] BIOS-e820: [mem 0x0000000000000000-0x000000000009fbff] usable', delay: 100 },
    { text: '[    0.204586] systemd[1]: Detected virtualization kvm.', delay: 200 },
    { text: '[    0.205752] systemd[1]: Set hostname to <server.binhpham.com>.', delay: 200 },
    { text: '[    0.435472] systemd[1]: Started Journal Service.', delay: 300 },
    { text: '[    1.245891] CPU0: Intel(R) Xeon(R) CPU @ 2.80GHz stepping 02', delay: 200 },
    { text: '[    1.456723] Memory: 16384MB DDR4', delay: 200 },
    { text: '[    2.123456] Checking NVME SSD drives...', delay: 300 },
    { text: '[    2.234567] NVME SSD: Samsung 970 EVO Plus 1TB detected', delay: 250 },
    { text: '[    2.345678] Initializing network interfaces...', delay: 300 },
    { text: '[    2.456789] eth0: Intel(R) I219-V Gigabit Network Connection', delay: 200 },
    { text: '[    2.567890] Loading initial ramdisk...', delay: 400 },
    { text: 'Starting system services...', delay: 400, color: '#50fa7b' },
    { text: 'Starting Binh System Manager...                               [ OK ]', delay: 300 },
    { text: 'Starting sshd...                                             [ OK ]', delay: 200 },
    { text: 'Starting network services...                                 [ OK ]', delay: 200 },
    { text: 'Checking file systems...                                     [ OK ]', delay: 300 },
    { text: 'Starting crond...                                           [ OK ]', delay: 200 },
    { text: 'Starting firewalld...                                       [ OK ]', delay: 200 },
    { text: 'Starting system logger...                                   [ OK ]', delay: 200 },
    { text: 'Mounting network filesystems...                             [ OK ]', delay: 300 },
    { text: 'Starting Docker services...                                 [ OK ]', delay: 250 },
    { text: 'Starting Kubernetes services...                             [ OK ]', delay: 250 },
    { text: 'Starting monitoring services...                             [ OK ]', delay: 200 },
    { text: '\nBinh Linux 2025 LTS Release Candidate', delay: 400, color: '#61dafb' },
    { text: 'Kernel 5.10.102-99.473.bnh2.x86_64 on an x86_64\n', delay: 300 },
    { text: 'Ready for login...', delay: 500, color: '#50fa7b' },
  ], []);

  useEffect(() => {
    if (bootContentRef.current) {
      bootContentRef.current.scrollTop = bootContentRef.current.scrollHeight;
    }
  }, [bootStep]);

  useEffect(() => {
    if (bootStep < bootMessages.length) {
      const timer = setTimeout(() => {
        setBootStep(prev => prev + 1);
      }, bootMessages[bootStep].delay);
      return () => clearTimeout(timer);
    } else {
      const finalTimer = setTimeout(() => {
        onBootComplete();
      }, 500);
      return () => clearTimeout(finalTimer);
    }
  }, [bootStep, onBootComplete, bootMessages]);

  return (
    <TerminalContent ref={bootContentRef}>
      {bootMessages.slice(0, bootStep).map((msg, index) => (
        <BootMessage key={index} color={msg.color}>
          {msg.text}
        </BootMessage>
      ))}
    </TerminalContent>
  );
};

const Terminal = ({ onClose, onMinimize, onFullscreen, isFullscreen }) => {
  // Add fileContents if it doesn't exist in the imported data
  const localFileContents = {
    '/home/guest/certifications/LPI/LPI_Linux_Essentials.txt': `LPI Linux Essentials
Issued: December 2024
Expiration: Lifetime

This certification verifies fundamental knowledge of:
• Linux command line basics
• System and user security
• File and directory management
• Operating systems and Linux distributions
• Open source software and licensing`,
    // ... any other file contents ...
  };

  Object.assign(fileContents, localFileContents);

  // Add new state for boot sequence
  const [isBooting, setIsBooting] = useState(true);
  
  // Add these helper functions at the start of the Terminal component
  const getRandomRecentDate = () => {
    const now = new Date();
    const hoursAgo = Math.floor(Math.random() * 48); // Random hours in last 2 days
    const minutesAgo = Math.floor(Math.random() * 60);
    const secondsAgo = Math.floor(Math.random() * 60);
    
    const date = new Date(now);
    date.setHours(date.getHours() - hoursAgo);
    date.setMinutes(date.getMinutes() - minutesAgo);
    date.setSeconds(date.getSeconds() - secondsAgo);
    
    return date.toLocaleString();
  };

  const getRandomIP = () => {
    // Generate random IP from common public ranges
    const ranges = [
      ['64', '69'],    // ARIN
      ['128', '191'],  // Various global allocations
      ['196', '223'],  // RIPE NCC
    ];
    
    const range = ranges[Math.floor(Math.random() * ranges.length)];
    const first = range[0];
    const second = Math.floor(Math.random() * (range[1] - range[0] + 1)) + parseInt(range[0]);
    const third = Math.floor(Math.random() * 256);
    const fourth = Math.floor(Math.random() * 256);
    
    return `${first}.${second}.${third}.${fourth}`;
  };

  const [commands, setCommands] = useState([]);
  const [input, setInput] = useState('');
  const [currentPath, setCurrentPath] = useState('/home/guest');
  const inputRef = useRef(null);
  const terminalContentRef = useRef(null);
  const initialPathRef = useRef(currentPath);
  const [isMinimized, setIsMinimized] = useState(false);
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  
  const rootStructure = [
    'bin',
    'boot',
    'dev',
    'etc',
    'home',
    'lib',
    'lib64',
    'local',
    'media',
    'mnt',
    'opt',
    'proc',
    'root',
    'run',
    'sbin',
    'srv',
    'sys',
    'tmp',
    'usr',
    'var',
  ];
  
  const folderStructure = {
    '/': rootStructure,
    '/home': ['guest', 'binh'],
    '/home/guest': [
      'certifications',
      'skills',
      'projects',
      'experience',
      'contact',
    ],
    '/home/guest/certifications': ['AWS', 'LPI'],
    '/home/guest/certifications/AWS': ['AWS_Cloud_Practitioner.txt'],
    '/home/guest/certifications/LPI': ['LPI_Linux_Essentials.txt'],

    '/home/guest/skills': ['MySQL', 'Python', 'Docker', 'VMWare', 'cPanel', 'Proxmox', 'Linux', ],
    '/home/guest/projects': ['PasswordGenerator', 'FindBoardGames', 'DomainsToHostfile', 'Scripts', 'Shard'],
    '/home/guest/experience': ['System_Engineer.txt', 'Advanced_Product_Support.txt', 'Tier_1_Expert.txt', 'Tier_1_Support.txt', 'Resident_Advisor.txt', 'IT_Consultant.txt'],
  };
  
  const getDisplayPath = useCallback((path) => {
    return path === '/home/guest' ? '~' : path;
  }, []);
  
  const handleContact = () => {
    window.location.href = 'mailto:bphamt@gmail.com';
  };
  
  const getDirContents = (path) => {
    return folderStructure[path] || [];
  };
  
  const handleTabCompletion = () => {
    if (!input.trim()) return;
  
    const inputParts = input.split(' ');
    const firstPart = inputParts[0];
    const partial = inputParts[1] || '';
  
    if (firstPart && ['cd', 'ls', 'cat', 'nano', 'vim', 'vi'].includes(firstPart)) {
      const contents = getDirContents(currentPath);
      const matches = contents.filter((item) =>
        item.toLowerCase().startsWith(partial.toLowerCase())
      );
  
      if (matches.length === 1) {
        // Single match - complete the word
        // Check if it's a directory by looking it up in folderStructure
        const fullPath = currentPath === '/' ? `/${matches[0]}` : `${currentPath}/${matches[0]}`;
        const isDirectory = folderStructure[fullPath] !== undefined;
        setInput(`${firstPart} ${matches[0]}${isDirectory ? '/' : ''}`);
      } else if (matches.length > 1) {
        // Multiple matches - find common prefix
        const commonPrefix = findCommonPrefix(matches);
        if (commonPrefix.length > partial.length) {
          // Check if common prefix is a complete directory name
          const fullPath = currentPath === '/' ? `/${commonPrefix}` : `${currentPath}/${commonPrefix}`;
          const isDirectory = matches.length === 1 && folderStructure[fullPath] !== undefined;
          setInput(`${firstPart} ${commonPrefix}${isDirectory ? '/' : ''}`);
        } else {
          // Show matches as a new command output
          setCommands(prev => [...prev, {
            text: input,
            output: matches.join('  '),
            path: getDisplayPath(currentPath)
          }]);
        }
      }
    } else if (input.startsWith('./')) {
      const partial = input.slice(2); // Remove './'
      const contents = getDirContents(currentPath);
      const matches = contents.filter((item) =>
        item.toLowerCase().startsWith(partial.toLowerCase())
      );
  
      if (matches.length === 1) {
        const fullPath = currentPath === '/' ? `/${matches[0]}` : `${currentPath}/${matches[0]}`;
        const isDirectory = folderStructure[fullPath] !== undefined;
        setInput(`./${matches[0]}${isDirectory ? '/' : ''}`);
      } else if (matches.length > 1) {
        const commonPrefix = findCommonPrefix(matches);
        if (commonPrefix.length > partial.length) {
          const fullPath = currentPath === '/' ? `/${commonPrefix}` : `${currentPath}/${commonPrefix}`;
          const isDirectory = matches.length === 1 && folderStructure[fullPath] !== undefined;
          setInput(`./${commonPrefix}${isDirectory ? '/' : ''}`);
        } else {
          setCommands(prev => [...prev, {
            text: input,
            output: matches.join('  '),
            path: getDisplayPath(currentPath)
          }]);
        }
      }
    }
  };
  
  // Add this helper function to find the common prefix among matches
  const findCommonPrefix = (strings) => {
    if (!strings.length) return '';
    if (strings.length === 1) return strings[0];
  
    let prefix = strings[0];
    for (let i = 1; i < strings.length; i++) {
      while (strings[i].toLowerCase().indexOf(prefix.toLowerCase()) !== 0) {
        prefix = prefix.substring(0, prefix.length - 1);
        if (prefix === '') return '';
      }
    }
    return prefix;
  };
  
  const handleInput = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      handleTabCompletion();
    } else if (e.key === 'Enter' && input.trim()) {
      const result = handleCommand(input.trim());
      if (result) {
        setCommands((prevCommands) => [...prevCommands, result]);
      }
      setInput('');
    } else if (e.key === 'l' && e.ctrlKey) {
      e.preventDefault();
      setCommands([]);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (commandHistory.length > 0) {
        const newIndex = Math.min(historyIndex + 1, commandHistory.length - 1);
        setHistoryIndex(newIndex);
        setInput(commandHistory[newIndex]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > -1) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setInput(newIndex === -1 ? '' : commandHistory[newIndex]);
      }
    }
  };
  
  const isRestrictedPath = (path) => {
    if (path === '/home' || path.startsWith('/home/guest')) {
      return false;
    }
    
    if (path === '/home/binh' || path.startsWith('/home/binh/')) {
      return true;
    }
    
    const restrictedPaths = rootStructure.map(dir => `/${dir}`);
    return restrictedPaths.some(restricted => path === restricted || path.startsWith(restricted + '/'));
  };
  
  const handleCommand = (command) => {
    if (!command) return null;
  
    // Add the command to history only if it's not empty and different from the last command
    if (command.trim() && (commandHistory.length === 0 || commandHistory[0] !== command)) {
      setCommandHistory(prev => [command, ...prev]);
    }
    setHistoryIndex(-1); // Reset history index after executing command
  
    const args = command.split(' ');
    const cmd = args[0];
    const param = args[1];
  
    const createCommandResult = (text, output, options = {}) => ({
      text,
      output,
      ...options,
    });
  
    if (cmd === './contact') {
      handleContact();
      return createCommandResult(command, 'Opening email client...', {
        path: getDisplayPath(currentPath),
      });
    }
  
    if (cmd.startsWith('./')) {
      const projectName = cmd.slice(2); // Remove './'
      if (currentPath === '/home/guest/projects' && folderStructure[currentPath].includes(projectName)) {
        handleProjectClick(projectName);
        return createCommandResult(command, `Opening ${projectName}...`, {
          path: getDisplayPath(currentPath),
        });
      }
      return createCommandResult(command, `${cmd}: No such file or directory`, {
        path: getDisplayPath(currentPath),
      });
    }
  
    switch (cmd) {
      case 'help':
        return createCommandResult(command, 
          `Available commands:
Type 'ls' or 'll' to see available directories
Type 'cd directory_name' to change directories
Type 'cat file_name' to read a file`, {
            path: getDisplayPath(currentPath)
          });
  
      case 'rm':
        // Check if it's the dangerous rm -rf /* command
        if (args.join(' ') === 'rm -rf /*') {
          const dangerSequence = [
            { text: 'rm: removing all files from system...', delay: 300 },
            { text: 'rm: removed /bin', delay: 200 },
            { text: 'rm: removed /boot', delay: 200 },
            { text: 'rm: removed /dev', delay: 200 },
            { text: 'rm: removed /etc', delay: 200 },
            { text: 'rm: removed /home', delay: 200 },
            { text: 'rm: removed /lib', delay: 200 },
            { text: 'rm: removed /lib64', delay: 200 },
            { text: 'rm: cannot remove /proc: Device or resource busy', delay: 200 },
            { text: 'rm: removed /root', delay: 200 },
            { text: 'rm: removed /sbin', delay: 200 },
            { text: 'rm: removed /sys', delay: 200 },
            { text: 'rm: removed /usr', delay: 200 },
            { text: 'rm: removed /var', delay: 200 },
            { text: '', delay: 500 },
            { text: 'Kernel panic - not syncing: Attempted to kill init!', delay: 300 },
            { text: 'CPU: 0 PID: 1 Comm: init Not tainted', delay: 200 },
            { text: 'Hardware name: Binh Linux/Virtual Machine', delay: 200 },
            { text: 'Call Trace:', delay: 200 },
            { text: ' [<ffffffffa3e13e81>] ? panic+0x1b1/0x1d6', delay: 100 },
            { text: ' [<ffffffffa3e13cd0>] ? panic+0x0/0x1d6', delay: 100 },
            { text: ' [<ffffffffa3e1c7f3>] ? do_exit+0xb83/0xb90', delay: 100 },
            { text: ' [<ffffffffa3e1c670>] ? do_exit+0xa00/0xb90', delay: 100 },
            { text: ' [<ffffffffa3e1c8bc>] ? do_group_exit+0x3c/0xa0', delay: 100 },
            { text: 'System halted.', delay: 500 }
          ];

          const showKernelPanic = async () => {
            setIsShuttingDown(true);
            setCommands(prev => [...prev, {
              text: command,
              output: '',
              path: getDisplayPath(currentPath),
              hidePrompt: true
            }]);

            for (const msg of dangerSequence) {
              await new Promise(resolve => setTimeout(resolve, msg.delay));
              setCommands(prev => {
                const newCommands = [...prev];
                newCommands[newCommands.length - 1] = {
                  ...newCommands[newCommands.length - 1],
                  output: (newCommands[newCommands.length - 1].output + msg.text + '\n'),
                  hidePrompt: true
                };
                return newCommands;
              });
            }

            // After the sequence, redirect to a 404 page or show an error screen
            setTimeout(() => {
              document.body.innerHTML = `
                <div style="
                  height: 100vh;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  font-family: monospace;
                  background: #000;
                  color: #fff;
                  padding: 20px;
                  text-align: center;
                ">
                  <h1 style="color: #ff5555;">KERNEL PANIC</h1>
                  <p>System halted due to catastrophic file system damage</p>
                  <button onclick="window.location.reload()" style="
                    margin-top: 20px;
                    padding: 10px 20px;
                    background: #ff5555;
                    border: none;
                    color: white;
                    cursor: pointer;
                    border-radius: 4px;
                  ">Re-OS System</button>
                </div>
              `;
            }, 1000);
          };

          showKernelPanic();
          return null;
        }
        // For any other rm command, show the normal "Nice try" message
        return createCommandResult(command, 'Nice try! 👀', {
          path: getDisplayPath(currentPath),
          isError: true
        });
  
      case 'sudo':
      case 'su':
      case 'chmod':
      case 'chown':
      case 'dd':
      case 'kill':
      case 'killall':
      case 'killall5':
      case 'shutdown':
      case 'passwd':
      case 'wget':
      case 'curl':
      case 'iptables':
      case 'ip6tables':
      case 'fuser':
      case 'systemctl':
        return createCommandResult(command, 'Nice try! 👀', {
          path: getDisplayPath(currentPath),
          isError: true
        });
  
      case 'history':
        return createCommandResult(command, commands.map((cmd, index) => 
          `${index + 1}  ${cmd.text}`
        ).join('\n'), {
          path: getDisplayPath(currentPath)
        });
  
      case 'pwd':
        return createCommandResult(command, currentPath, {
          path: getDisplayPath(currentPath),
        });
  
      case 'ls':
        const flags = args.slice(1);
        const dirContents = getDirContents(currentPath);
        
        if (isRestrictedPath(currentPath)) {
          return createCommandResult(command, 'ls: Permission denied', {
            path: getDisplayPath(currentPath)
          });
        }

        // Handle ls with flags
        if (flags.length > 0) {
          const showHidden = flags.some(flag => flag.includes('a'));
          const showDetails = flags.some(flag => flag.includes('l'));
          const showHumanReadable = flags.some(flag => flag.includes('h'));

          if (showDetails) {
            const now = new Date();
            let contents = dirContents;
            
            // Add hidden entries if -a flag is present
            if (showHidden) {
              contents = ['.', '..', ...contents];
            }

            const fileList = contents.map(item => {
              const fullPath = currentPath === '/' ? `/${item}` : `${currentPath}/${item}`;
              const isDir = folderStructure[fullPath] !== undefined || item === '.' || item === '..';
              let size = isDir ? 4096 : Math.floor(Math.random() * 10000);
              
              // Format size if -h flag is present
              if (showHumanReadable && !isDir) {
                if (size > 1024 * 1024) {
                  size = `${(size / (1024 * 1024)).toFixed(1)}M`;
                } else if (size > 1024) {
                  size = `${(size / 1024).toFixed(1)}K`;
                } else {
                  size = `${size}`;
                }
              }
              
              const date = new Date(now - Math.random() * 30 * 24 * 60 * 60 * 1000);
              const dateStr = `${date.toLocaleString('default', { month: 'short' })} ${date.getDate().toString().padStart(2)} ${date.toLocaleTimeString()}`;
              
              // Determine owner based on path and item
              let owner = 'guest';
              let group = 'guest';
              
              if (currentPath === '/') {
                owner = 'root';
                group = 'root';
              } else if (currentPath === '/home' && item === 'binh') {
                owner = 'binh';
                group = 'binh';
              } else if (currentPath.startsWith('/home/binh')) {
                owner = 'binh';
                group = 'binh';
              }

              const permissions = isDir ? 'drwxr-xr-x' : '-rw-r--r--';
              const links = isDir ? 2 : 1;
              
              return `${permissions} ${links} ${owner} ${group} ${size.toString().padStart(8)} ${dateStr} ${item}`;
            }).join('\n');

            return createCommandResult(command, `total ${contents.length * 4}\n${fileList}`, {
              path: getDisplayPath(currentPath)
            });
          }
        }

        // Default ls behavior (no flags)
        return createCommandResult(command, null, {
          isLs: true,
          dirContents,
          path: getDisplayPath(currentPath),
          absolutePath: currentPath,
        });
  
      case 'clear':
        // Add the clear command to history but with a special flag
        const clearCommand = {
          text: command,
          output: null,
          path: getDisplayPath(currentPath),
          isClear: true
        };
        
        // Keep all previous commands but add a clear marker
        setCommands(prev => [...prev, clearCommand]);
        return null;
  
      case 'cd':
        let newPath = currentPath;
        let output = null;
  
        if (!param || param === '~') {
          newPath = '/home/guest';
        } else if (param === '..') {
          if (currentPath !== '/') {
            const potentialPath = currentPath.substring(0, currentPath.lastIndexOf('/')) || '/';
            if (!isRestrictedPath(potentialPath)) {
              newPath = potentialPath;
            } else {
              output = 'cd: Permission denied';
            }
          }
        } else if (param.startsWith('/')) {
          // Remove trailing slash if present
          const cleanParam = param.endsWith('/') ? param.slice(0, -1) : param;
          if (isRestrictedPath(cleanParam)) {
            output = 'cd: Permission denied';
          } else if (fileContents[cleanParam]) {
            output = `cd: ${param}: Not a directory`;
          } else if (folderStructure[cleanParam]) {
            newPath = cleanParam;
          } else {
            output = `cd: ${param}: No such file or directory`;
          }
        } else {
          // Remove trailing slash if present
          const cleanParam = param.endsWith('/') ? param.slice(0, -1) : param;
          let potentialPath = currentPath === '/' ? `/${cleanParam}` : `${currentPath}/${cleanParam}`;
          if (isRestrictedPath(potentialPath)) {
            output = 'cd: Permission denied';
          } else if (fileContents[potentialPath]) {
            output = `cd: ${param}: Not a directory`;
          } else if (folderStructure[potentialPath]) {
            newPath = potentialPath;
          } else {
            output = `cd: ${param}: No such file or directory`;
          }
        }
  
        const result = createCommandResult(command, output, {
          path: getDisplayPath(currentPath),
        });
        setCurrentPath(newPath);
        return result;
  
      case 'cat':
      case 'nano':
      case 'vim':
      case 'vi':
        if (!param) {
          return createCommandResult(command, `${cmd}: missing file operand`, {
            path: getDisplayPath(currentPath)
          });
        }

        const filePath = param.startsWith('/') 
          ? param 
          : `${currentPath}/${param}`;

        if (!fileContents[filePath]) {
          return createCommandResult(command, `${cmd}: ${param}: No such file`, {
            path: getDisplayPath(currentPath)
          });
        }

        if (cmd === 'cat') {
          return createCommandResult(command, fileContents[filePath], {
            path: getDisplayPath(currentPath)
          });
        } else {
          return createCommandResult(command, `${cmd}: readonly file system - use 'cat' instead to read files`, {
            path: getDisplayPath(currentPath)
          });
        }
  
      case 'hostname':
        return createCommandResult(command, 'server.binhpham.com', {
          path: getDisplayPath(currentPath)
        });
  
      case 'reboot':
        const rebootSequence = [
          { text: 'clear', delay: 100, isClear: true },
          { text: 'Broadcast message from guest@server.binhpham.com', delay: 300, hidePrompt: true },
          { text: `        (/dev/pts/0) at ${new Date().toLocaleTimeString()}...`, delay: 200, hidePrompt: true },
          { text: '', delay: 100, hidePrompt: true },
          { text: 'The system is going down for reboot NOW!', delay: 500, hidePrompt: true },
          { text: '', delay: 200, hidePrompt: true },
          { text: 'Stopping Docker containers...                                [ OK ]', delay: 300, hidePrompt: true },
          { text: 'Stopping Kubernetes services...                             [ OK ]', delay: 300, hidePrompt: true },
          { text: 'Stopping Apache web server...                              [ OK ]', delay: 200, hidePrompt: true },
          { text: 'Stopping MySQL database...                                 [ OK ]', delay: 300, hidePrompt: true },
          { text: 'Stopping all system services...                            [ OK ]', delay: 300, hidePrompt: true },
          { text: 'Saving system state...                                     [ OK ]', delay: 250, hidePrompt: true },
          { text: 'Syncing all filesystems...                                 [ OK ]', delay: 300, hidePrompt: true },
          { text: 'Unmounting all filesystems...                              [ OK ]', delay: 300, hidePrompt: true },
          { text: '', delay: 200, hidePrompt: true },
          { text: 'System going down for reboot...', delay: 400, hidePrompt: true },
          { text: 'How does guest have reboot permission??', delay: 300, hidePrompt: true },
          { text: 'Please stand by...', delay: 300, hidePrompt: true },
          { text: '', delay: 500, hidePrompt: true },
          { text: '[  OK  ] Reached target Shutdown.', delay: 300, hidePrompt: true },
          { text: 'Rebooting system...', delay: 1000, hidePrompt: true }
        ];

        // Show reboot messages before actual reboot
        const showRebootMessages = async () => {
          setIsShuttingDown(true);
          
          for (const msg of rebootSequence) {
            await new Promise(resolve => setTimeout(resolve, msg.delay));
            if (msg.isClear) {
              setCommands([]);
            } else {
              setCommands(prev => {
                if (prev.length === 0) {
                  return [{
                    text: '',
                    output: msg.text + '\n',
                    path: getDisplayPath(currentPath),
                    hidePrompt: msg.hidePrompt
                  }];
                }
                const newCommands = [...prev];
                newCommands[newCommands.length - 1] = {
                  ...newCommands[newCommands.length - 1],
                  output: (newCommands[newCommands.length - 1].output + msg.text + '\n'),
                  hidePrompt: msg.hidePrompt
                };
                return newCommands;
              });
            }
          }

          // Clear terminal and start boot sequence after messages
          setTimeout(() => {
            setCommands([]);
            setCurrentPath('/home/guest');
            setIsBooting(true);
            setIsShuttingDown(false);
          }, 1000);
        };

        showRebootMessages();
        return null;
  
      case 'whoami':
        return createCommandResult(command, 'guest', {
          path: getDisplayPath(currentPath)
        });
  
      case 'date':
        return createCommandResult(command, new Date().toString(), {
          path: getDisplayPath(currentPath)
        });
  
      case 'uname':
        if (param === '-a') {
          return createCommandResult(command, 
            'Linux server.binhpham.com 5.10.102-99.473.bnh2.x86_64 #1 SMP Thu Mar 17 17:08:06 EDT 2024 x86_64 x86_64 x86_64 GNU/Linux', {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, 'Linux', {
          path: getDisplayPath(currentPath)
        });
  
      case 'uptime':
        const uptime = Math.floor(Math.random() * 24) + 1; // Random hours up to 24
        const users = Math.floor(Math.random() * 5) + 1;   // Random number of users
        const load = [
          (Math.random() * 0.5).toFixed(2),
          (Math.random() * 0.3).toFixed(2),
          (Math.random() * 0.2).toFixed(2)
        ];
        return createCommandResult(command, 
          ` ${new Date().toLocaleTimeString()} up ${uptime} hours, ${users} users, load average: ${load.join(', ')}`, {
          path: getDisplayPath(currentPath)
        });
  
      case 'df':
        if (param === '-h') {
          return createCommandResult(command,
            `Filesystem      Size  Used Avail Use% Mounted on
/dev/nvme0n1p2  932G  423G  462G  48% /
/dev/nvme0n1p1  511M   88M  424M  18% /boot
tmpfs           7.8G     0  7.8G   0% /tmp`, {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, 'df: missing operand\nTry \'df -h\' for human readable format', {
          path: getDisplayPath(currentPath)
        });
  
      case 'free':
        if (param === '-h') {
          return createCommandResult(command,
            `               total        used        free      shared  buff/cache   available
Mem:           15.6Gi       4.2Gi       8.1Gi       1.1Gi       3.3Gi       9.9Gi
Swap:          4.0Gi          0B       4.0Gi`, {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, 'free: missing operand\nTry \'free -h\' for human readable format', {
          path: getDisplayPath(currentPath)
        });
  
      case 'ps':
        if (param === 'aux') {
          return createCommandResult(command,
            `USER         PID %CPU %MEM    VSZ   RSS TTY      STAT START   TIME COMMAND
root           1  0.0  0.0 169324  9488 ?        Ss   Mar17   0:08 /sbin/init
root         598  0.0  0.0  32472  3248 ?        Ss   Mar17   0:00 /usr/sbin/cron
mysql       1029  0.2  2.1 1839576 334576 ?      Ssl  Mar17   8:12 /usr/sbin/mysqld
www-data    1832  0.0  0.4 214992 65928 ?        S    Mar17   0:02 /usr/sbin/apache2
guest      15015  0.0  0.0  12784  7156 pts/0    Ss   14:23   0:00 -bash`, {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, 'ps: try \'ps aux\' for full process list', {
          path: getDisplayPath(currentPath)
        });
  
      case 'echo':
        if (!param) {
          return createCommandResult(command, '', {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, args.slice(1).join(' '), {
          path: getDisplayPath(currentPath)
        });
  
      case 'which':
        if (!param) {
          return createCommandResult(command, 'which: missing operand\nTry \'which [command]\' to locate a command', {
            path: getDisplayPath(currentPath)
          });
        }
        const commonPaths = {
          ls: '/usr/bin/ls',
          cd: 'cd: shell built-in command',
          cat: '/usr/bin/cat',
          echo: '/usr/bin/echo',
          pwd: '/usr/bin/pwd',
          ps: '/usr/bin/ps',
          df: '/usr/bin/df',
          free: '/usr/bin/free',
          whoami: '/usr/bin/whoami',
          date: '/usr/bin/date',
          uname: '/usr/bin/uname',
          hostname: '/usr/bin/hostname',
          ifconfig: '/usr/sbin/ifconfig'
        };
        return createCommandResult(command, commonPaths[param] || `which: no ${param} in ($PATH)`, {
          path: getDisplayPath(currentPath)
        });
  
      case 'id':
        return createCommandResult(command, 
          'uid=1000(guest) gid=1000(guest) groups=1000(guest),4(adm),24(cdrom),27(sudo),30(dip),46(plugdev),120(lpadmin),131(lxd),132(sambashare)', {
          path: getDisplayPath(currentPath)
        });
  
      case 'w':
        const currentTime = new Date();
        const uptimeHours = Math.floor(Math.random() * 24) + 1;
        const loadAvg = [
          (Math.random() * 0.5).toFixed(2),
          (Math.random() * 0.3).toFixed(2),
          (Math.random() * 0.2).toFixed(2)
        ];
        return createCommandResult(command,
          ` ${currentTime.toLocaleTimeString()} up ${uptimeHours} hours,  1 user,  load average: ${loadAvg.join(', ')}
USER     TTY      FROM             LOGIN@   IDLE   JCPU   PCPU WHAT
guest    pts/0    ${getRandomIP()}    ${new Date(currentTime - 1000*60*30).toLocaleTimeString()}    0.00s  0.04s  0.00s w`, {
          path: getDisplayPath(currentPath)
        });
  
      case 'top':
        return createCommandResult(command,
          `top - ${new Date().toLocaleTimeString()} up ${Math.floor(Math.random() * 24)}:${Math.floor(Math.random() * 60)},  1 user,  load average: ${(Math.random() * 0.5).toFixed(2)}, ${(Math.random() * 0.3).toFixed(2)}, ${(Math.random() * 0.2).toFixed(2)}
Tasks: ${Math.floor(Math.random() * 100) + 150} total,   1 running, ${Math.floor(Math.random() * 100) + 150} sleeping,   0 stopped,   0 zombie
%Cpu(s):  2.4 us,  1.2 sy,  0.0 ni, 96.3 id,  0.0 wa,  0.0 hi,  0.1 si,  0.0 st
MiB Mem :  15907.7 total,   8294.9 free,   4302.8 used,   3310.0 buff/cache
MiB Swap:   4096.0 total,   4096.0 free,      0.0 used.  10153.4 avail Mem 

    PID USER      PR  NI    VIRT    RES    SHR S  %CPU  %MEM     TIME+ COMMAND
   1029 mysql     20   0 1839576 334576  17024 S   0.3   2.1   8:12.23 mysqld
   1832 www-data  20   0  214992  65928  23416 S   0.0   0.4   0:02.31 apache2
  15015 guest     20   0   12784   7156   5352 S   0.0   0.0   0:00.12 bash
      1 root      20   0  169324   9488   6992 S   0.0   0.0   0:08.45 systemd
    598 root      20   0   32472   3248   2664 S   0.0   0.0   0:00.07 cron

`, {
          path: getDisplayPath(currentPath)
        });
  
      case 'ip':
        if (param === 'addr') {
          return createCommandResult(command,
            `1: lo: <LOOPBACK,UP,LOWER_UP> mtu 65536 qdisc noqueue state UNKNOWN group default qlen 1000
    link/loopback 00:00:00:00:00:00 brd 00:00:00:00:00:00
    inet 127.0.0.1/8 scope host lo
       valid_lft forever preferred_lft forever
    inet6 ::1/128 scope host 
       valid_lft forever preferred_lft forever
2: eth0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state UP group default qlen 1000
    link/ether 00:15:5d:01:ca:05 brd ff:ff:ff:ff:ff:ff
    inet ${getRandomIP()}/24 brd 192.168.1.255 scope global dynamic eth0
       valid_lft 86389sec preferred_lft 86389sec
    inet6 fe80::215:5dff:fe01:ca05/64 scope link 
       valid_lft forever preferred_lft forever`, {
            path: getDisplayPath(currentPath)
          });
        }
        return createCommandResult(command, 'Usage: ip addr', {
          path: getDisplayPath(currentPath)
        });
  
      case 'll':
        // Alias for ls -l
        const contents = getDirContents(currentPath);
        if (isRestrictedPath(currentPath)) {
          return createCommandResult(command, 'ls: Permission denied', {
            path: getDisplayPath(currentPath)
          });
        }
        const now = new Date();
        const fileList = contents.map(item => {
          const fullPath = currentPath === '/' ? `/${item}` : `${currentPath}/${item}`;
          const isDir = folderStructure[fullPath] !== undefined;
          const size = isDir ? 4096 : Math.floor(Math.random() * 10000);
          const date = new Date(now - Math.random() * 30 * 24 * 60 * 60 * 1000);
          
          // Determine owner based on path and item
          let owner = 'guest';
          let group = 'guest';
          
          if (currentPath === '/') {
            owner = 'root';
            group = 'root';
          } else if (currentPath === '/home' && item === 'binh') {
            owner = 'binh';
            group = 'binh';
          } else if (currentPath.startsWith('/home/binh')) {
            owner = 'binh';
            group = 'binh';
          }
          
          return `${isDir ? 'd' : '-'}rw-r--r-- 1 ${owner} ${group} ${size.toString().padStart(8)} ${date.toLocaleString('default', { month: 'short' })} ${date.getDate().toString().padStart(2)} ${date.toLocaleTimeString()} ${item}`;
        }).join('\n');
        return createCommandResult(command, `total ${contents.length * 4}\n${fileList}`, {
          path: getDisplayPath(currentPath)
        });
  
      case 'ifconfig':
        return createCommandResult(command,
          `eth0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
        inet ${getRandomIP()}/24 brd 192.168.1.255 scope global dynamic eth0
       valid_lft 86389sec preferred_lft 86389sec
    inet6 fe80::215:5dff:fe01:ca05/64 scope link 
       valid_lft forever preferred_lft forever

lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
        inet 127.0.0.1  netmask 255.0.0.0
        inet6 ::1  prefixlen 128  scopeid 0x10<host>
        loop  txqueuelen 1000  (Local Loopback)
        RX packets 356  bytes 32876 (32.1 KiB)
        RX errors 0  dropped 0  overruns 0  frame 0
        TX packets 356  bytes 32876 (32.1 KiB)
        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0

docker0: flags=4099<UP,BROADCAST,MULTICAST>  mtu 1500
        inet 172.17.0.1  netmask 255.255.0.0  broadcast 172.17.255.255
        ether 02:42:8d:95:04:fb  txqueuelen 0  (Ethernet)
        RX packets 0  bytes 0 (0.0 B)
        RX errors 0  dropped 0  overruns 0  frame 0
        TX packets 0  bytes 0 (0.0 B)
        TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0`, {
          path: getDisplayPath(currentPath)
        });
  
      default:
        return createCommandResult(command, `${command}: command not found`, {
          path: getDisplayPath(currentPath),
        });
    }
  };
  
  const handleTerminalClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  const formatErrorMessage = (message) => {
    if (!message) return null;
    if (message.includes('Permission denied')) {
      return <span className="permission-denied">Permission denied</span>;
    }
    if (message === 'Nice try! 👀') {
      return <span className="permission-denied">{message}</span>;
    }
    if (message.includes('command not found')) {
      const parts = message.split(': ');
      return (
        <>
          {parts[0]}: <span>command not found</span>
        </>
      );
    }
    return message;
  };
  
  const handleFolderClick = (item, absolutePath) => {
    if (item === '..') {
      // Handle parent directory navigation
      if (absolutePath !== '/') {
        const parentPath = absolutePath.substring(0, absolutePath.lastIndexOf('/')) || '/';
        if (!isRestrictedPath(parentPath)) {
          setCurrentPath(parentPath);
          setCommands(prev => [...prev, {
            text: 'cd ..',
            output: null,
            path: getDisplayPath(absolutePath)
          }]);
        } else {
          setCommands(prev => [...prev, {
            text: 'cd ..',
            output: 'Permission denied',
            path: getDisplayPath(absolutePath)
          }]);
        }
      }
      return;
    }

    // Handle regular directory navigation
    const newPath = absolutePath === '/' ? `/${item}` : `${absolutePath}/${item}`;
    if (!isRestrictedPath(newPath) && folderStructure[newPath]) {
      setCurrentPath(newPath);
      setCommands(prev => [...prev, {
        text: `cd ${item}`,
        output: null,
        path: getDisplayPath(absolutePath)
      }]);
    } else if (isRestrictedPath(newPath)) {
      setCommands(prev => [...prev, {
        text: `cd ${item}`,
        output: 'Permission denied',
        path: getDisplayPath(absolutePath)
      }]);
    }
  };
  
  const handleProjectClick = (project) => {
    const projectUrls = {
      'PasswordGenerator': 'https://passwordgenerator.binhpham.com/',
      'FindBoardGames': 'https://findboard.games/',
      'Scripts': 'https://scripts.binhpham.com/',
      'DomainsToHostfile': 'https://domainstohostfile.com/',
      'Shard': 'https://theshard.app/'
    };

    if (projectUrls[project]) {
      window.open(projectUrls[project], '_blank');
    }
  };
  
  const handleTextFileClick = (filename, absolutePath) => {
    const filePath = absolutePath === '/' ? `/${filename}` : `${absolutePath}/${filename}`;
    const fileContent = fileContents[filePath];
    
    if (fileContent) {
      setCommands(prev => [...prev, {
        text: `cat ${filename}`,
        output: fileContent,
        path: getDisplayPath(absolutePath)
      }]);
    }
  };
  
  const renderCommandOutput = (cmd) => {
    if (cmd.isWelcome) {
      return <WelcomeMessage>{cmd.output}</WelcomeMessage>;
    }
    if (cmd.isLs) {
      const items = cmd.dirContents;
      const absolutePath = cmd.absolutePath;
      const isLatestCommand = commands[commands.length - 1] === cmd;
      const showParentDir = absolutePath !== '/';
      const isProjectsDir = absolutePath === '/home/guest/projects';
      const isSkillsDir = absolutePath === '/home/guest/skills';

      return (
        <FolderList>
          {showParentDir && (
            <Folder 
              key="parent-dir" 
              onClick={isLatestCommand ? () => handleFolderClick('..', absolutePath) : undefined}
              style={{ cursor: isLatestCommand ? 'pointer' : 'default' }}
            >
              ..
            </Folder>
          )}
          {items.map((item, index) => {
            if (isProjectsDir && isLatestCommand) {
              return (
                <ProjectFile
                  key={index}
                  onClick={() => handleProjectClick(item)}
                >
                  {item}
                </ProjectFile>
              );
            } else if (item === 'contact' && absolutePath === '/home/guest') {
              return (
                <Script 
                  key={index} 
                  onClick={isLatestCommand ? handleContact : undefined}
                  style={{ cursor: isLatestCommand ? 'pointer' : 'default' }}
                >
                  contact
                </Script>
              );
            } else if (isSkillsDir) {
              return (
                <SkillItem key={index}>
                  {item}
                </SkillItem>
              );
            } else if (item.endsWith('.txt')) {
              return (
                <TextFile 
                  key={index}
                  onClick={() => handleTextFileClick(item, absolutePath)}
                  title={`cat ${item}`}
                >
                  {item}
                </TextFile>
              );
            } else {
              return (
                <Folder 
                  key={index} 
                  onClick={isLatestCommand ? () => handleFolderClick(item, absolutePath) : undefined}
                  style={{ cursor: isLatestCommand ? 'pointer' : 'default' }}
                >
                  {item}
                </Folder>
              );
            }
          })}
        </FolderList>
      );
    }
    return cmd.output && (
      <ErrorMessage>{formatErrorMessage(cmd.output)}</ErrorMessage>
    );
  };
  
  const scrollToBottom = () => {
    if (terminalContentRef.current) {
      terminalContentRef.current.scrollTop = terminalContentRef.current.scrollHeight;
    }
  };
  
  useEffect(() => {
    scrollToBottom();
  }, [commands]);
  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [commands]);
  
  useEffect(() => {
      // Add welcome message when component mounts
      const welcomeText = ` ______     __     __   __     __  __    
/\\  == \\   /\\ \\   /\\ "-.\\ \\   /\\ \\_\\ \\   
\\ \\  __<   \\ \\ \\  \\ \\ \\-.  \\  \\ \\  __ \\  
 \\ \\_____\\  \\ \\_\\  \\ \\_\\ "\\_\\  \\ \\_\\ \\_\\ 
  \\/_____/   \\/_/   \\/_/ \\/_/   \\/_/\\/_/ 
                                         
          Binh Linux 2025 ©
          

Last login: ${getRandomRecentDate()} from ${getRandomIP()}

Type 'help' to see available commands\n`;

      setCommands([{
          text: '',
          output: welcomeText,
          path: getDisplayPath(initialPathRef.current),
          isWelcome: true
      }]);
  }, [getDisplayPath]);
  
  const handleBootComplete = () => {
    setIsBooting(false);
    // Add welcome message after boot
    const welcomeText = ` ______     __     __   __     __  __    
/\\  == \\   /\\ \\   /\\ "-.\\ \\   /\\ \\_\\ \\   
\\ \\  __<   \\ \\ \\  \\ \\ \\-.  \\  \\ \\  __ \\  
 \\ \\_____\\  \\ \\_\\  \\ \\_\\ "\\_\\  \\ \\_\\ \\_\\ 
  \\/_____/   \\/_/   \\/_/ \\/_/   \\/_/\\/_/ 
                                         
          Binh Linux 2025 ©
          

Last login: ${getRandomRecentDate()} from ${getRandomIP()}

Type 'help' to see available commands\n`;

    setCommands([{
      text: '',
      output: welcomeText,
      path: getDisplayPath(currentPath),
      isWelcome: true
    }]);
  };

  // Add a new state to track if we're shutting down
  const [isShuttingDown, setIsShuttingDown] = useState(false);

  // Modify the return statement to hide prompt during shutdown
  return (
    <TerminalWrapper 
      onClick={handleTerminalClick}
      isMinimized={isMinimized}
      isFullscreen={isFullscreen}
    >
      <HeaderBar isMinimized={isMinimized} isFullscreen={isFullscreen}>
        <Circle 
          color="#ff5f57" 
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }} 
        />
        <Circle 
          color="#ffbd2e" 
          onClick={(e) => {
            e.stopPropagation();
            setIsMinimized(!isMinimized);
            if (onMinimize) onMinimize(!isMinimized);
          }} 
        />
        <Circle 
          color="#28c940" 
          onClick={(e) => {
            e.stopPropagation();
            onFullscreen();
          }} 
        />
      </HeaderBar>
      {!isMinimized && (
        isBooting ? (
          <BootScreen onBootComplete={handleBootComplete} />
        ) : (
          <TerminalContent ref={terminalContentRef}>
            {commands.map((cmd, index) => {
              // Find the last clear command
              const lastClearIndex = commands.findLastIndex(c => c.isClear);
              
              // Only show commands that came after the last clear
              if (lastClearIndex !== -1 && index <= lastClearIndex) {
                return null;
              }
              
              return (
                <CommandOutput key={index}>
                  {!cmd.isWelcome && !cmd.hidePrompt && (
                    <CommandLine>
                      <Prompt>[guest@binhpham.com {cmd.path}]$&nbsp;</Prompt>
                      <CommandText>{cmd.text}</CommandText>
                    </CommandLine>
                  )}
                  {renderCommandOutput(cmd)}
                </CommandOutput>
              );
            })}
            {/* Only show input wrapper if not shutting down */}
            {!isShuttingDown && (
              <InputWrapper>
                <Prompt>
                  [guest@binhpham.com {getDisplayPath(currentPath)}]$ &nbsp;
                  {!input && <BlinkingCursor />}
                </Prompt>
                <Input
                  ref={inputRef}
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleInput}
                  spellCheck="false"
                />
              </InputWrapper>
            )}
          </TerminalContent>
        )
      )}
    </TerminalWrapper>
  );
};

export default Terminal;