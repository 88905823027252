import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Terminal from './components/Terminal';
import Footer from './components/Footer';
import { ThemeProvider, useTheme } from './context/ThemeContext';

const AppWrapper = styled.div`
  background-color: var(--bg-primary);
  background-image: ${props => !props.isDarkMode && `
    linear-gradient(135deg, transparent 75%, rgba(248, 250, 252, 0.4) 75%),
    linear-gradient(225deg, transparent 75%, rgba(248, 250, 252, 0.4) 75%),
    linear-gradient(45deg, transparent 75%, rgba(248, 250, 252, 0.4) 75%),
    linear-gradient(315deg, transparent 75%, rgba(248, 250, 252, 0.4) 75%)
  `};
  background-position: ${props => !props.isDarkMode && '0 0, 10px 0, 10px -10px, 0px 10px'};
  background-size: ${props => !props.isDarkMode && '20px 20px'};
  color: var(--text-primary);
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(15px, 2vw, 30px);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: clamp(20px, 3vw, 40px);
  width: min(95%, 1400px);
  align-items: center;
  box-sizing: border-box;
  padding-right: clamp(10px, 2vw, 20px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  ${props => !props.showTerminal && `
    justify-content: center;
    gap: 0;
  `}

  @media (max-width: 1024px) {
    width: min(100%, 1400px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    padding-right: clamp(15px, 4vw, 30px);
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  padding-top: clamp(10px, 2vh, 20px);
  width: 100%;
  max-width: 600px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  ${props => !props.showTerminal && `
    transform: translateX(0);
  `}
`;

const RightSection = styled.div`
  flex: 1.5;
  width: 100%;
  max-width: 800px;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  &.exit {
    opacity: 0;
    transform: translateX(50px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const App = () => {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
};

const AppContent = () => {
  const { isDarkMode } = useTheme();
  const [showTerminal, setShowTerminal] = useState(true);
  const [isExiting, setIsExiting] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleTerminalClose = () => {
    setIsExiting(true);
    setIsFullscreen(false);
    setTimeout(() => {
      setShowTerminal(false);
      setIsExiting(false);
    }, 500);
  };

  const handleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <AppWrapper isDarkMode={isDarkMode}>
      <ContentContainer showTerminal={showTerminal}>
        <LeftSection 
          showTerminal={showTerminal} 
          style={{ display: isFullscreen ? 'none' : 'flex' }}
        >
          <Header />
          <Footer />
        </LeftSection>
        {showTerminal && (
          <RightSection 
            className={isExiting ? 'exit' : ''}
            style={{ 
              maxWidth: isFullscreen ? '100%' : '800px',
              flex: isFullscreen ? '1' : '1.5'
            }}
          >
            <Terminal 
              onClose={handleTerminalClose}
              onFullscreen={handleFullscreen}
              isFullscreen={isFullscreen}
            />
          </RightSection>
        )}
      </ContentContainer>
    </AppWrapper>
  );
};

export default App;
